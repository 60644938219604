import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { IBook } from '../../interfaces/IBook';
import { RootState } from '../store';
import { db, storage } from '../../services/firebase';
import { asyncForEach } from '../../helpers/async-foreach';
import { orderByValue } from '../../helpers/order-array';

// action types
export const actionType = {
  GET_BOOK_REQUEST: 'GET_BOOK_REQUEST',
  GET_BOOKS_FROM_DB: 'GET_BOOKS_FROM_DB',
  SET_CREATE_BOOK_SUCCESS: 'SET_CREATE_BOOK_SUCCESS',
  SET_BOOK_TO_EDIT_SUCCESS: 'SET_BOOK_TO_EDIT_SUCCESS',
  SET_DELETE_BOOK_SUCCESS: 'SET_DELETE_BOOK_SUCCESS',
  SET_UPDATE_BOOK_SUCCESS: 'SET_UPDATE_BOOK_SUCCESS',
  SET_LOADING_BOOK: 'SET_LOADING_BOOK',
  GET_BOOKS_BY_LIST: 'GET_BOOKS_BY_LIST',
};

// Interfaces
export interface BookState {
  books: IBook[] | null;
  bookById: null;
}

export interface setGetBooksSuccess {
  type: typeof actionType.GET_BOOKS_FROM_DB;
  payload: IBook[];
}

export interface setCreateBookSuccess {
  type: typeof actionType.SET_CREATE_BOOK_SUCCESS;
}

export interface setDeleteBookSuccess {
  type: typeof actionType.SET_DELETE_BOOK_SUCCESS;
}

export interface setBookToEditSuccess {
  type: typeof actionType.SET_BOOK_TO_EDIT_SUCCESS;
  payload: IBook;
}

export interface setBookUpdateSuccess {
  type: typeof actionType.SET_UPDATE_BOOK_SUCCESS;
}

export interface setLoadingBook {
  type: typeof actionType.SET_LOADING_BOOK;
  payload: boolean;
}

// Thunk Types
type BookActions =
  | setGetBooksSuccess
  | setCreateBookSuccess
  | setDeleteBookSuccess
  | setBookToEditSuccess
  | setLoadingBook;

// initial state
const initialState = {
  books: [] as any,
  bookById: null,
  loading: false,
  booksCourse: [],
};

// reducer
export default function bookReducer(
  state = initialState, action: AnyAction,
) : any {
  const { type, payload } = action;

  switch ( type ) {
    case actionType.GET_BOOKS_FROM_DB:
      return {
        ...state,
        books: payload,
      };
    case actionType.SET_BOOK_TO_EDIT_SUCCESS:
      return {
        ...state,
        bookById: payload,
        loading: false,
      };
    case actionType.GET_BOOK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.SET_LOADING_BOOK:
      return {
        ...state,
        loading: payload,
      };
    case actionType.SET_CREATE_BOOK_SUCCESS:
    case actionType.SET_DELETE_BOOK_SUCCESS:
      return state;
    case actionType.GET_BOOKS_BY_LIST:
      return {
        ...state,
        booksCourse: payload,
      };
    default:
      return state;
  }
}

// actions creators
export const actions = {
  getBooks:
    (): ThunkAction<
    void, RootState, null, BookActions> => async ( dispatch ) => {
      try {
        db.collection( 'books' )
          .where( 'deleted', '==', false )
          .orderBy( 'name', 'asc' )
          .onSnapshot(( querySnapshot ) => {
            const books: IBook[] = [];
            querySnapshot.forEach(( doc ) => {
              const book = doc.data() as IBook;
              book.id = doc.id;
              books.push( book );
            });
            dispatch({
              type: actionType.GET_BOOKS_FROM_DB,
              payload: books,
            });
          });
        // console.log( 'Libros cargados Correctamente' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  getBook:
    ( id: string ): ThunkAction<
    void, RootState, null, BookActions> => async ( dispatch ) => {
      try {
        const data = await db.collection( 'books' ).doc( id ).get();
        if ( data.exists ) {
          const bookSelected = { ...data.data(), id };
          dispatch({
            type: actionType.SET_BOOK_TO_EDIT_SUCCESS,
            payload: bookSelected,
          });
          // console.log( 'Libro cargado Correctamente' );
        }
      } catch ( err ) {
        // console.error( err );
      }
    },
  createBook:
    ( data: IBook, file: File ): ThunkAction<
    void, RootState, null, BookActions> => async ( dispatch ) => {
      const dataToSave = {
        ...data,
        deleted: false,
      };
      try {
        const { id } = await db.collection( 'books' ).add( dataToSave );
        const bookNew = { ...dataToSave, id };
        dispatch( actions.updateBook( id, bookNew, file ));
        toast.success( 'Nuevo libro agregado.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  updateBook:
    ( id: string, data: IBook, file?: File ): ThunkAction<
    void, RootState, null, BookActions> => async ( dispatch ) => {
      try {
        let book;
        let url;
        if ( file ) {
          const storageRef = storage.ref();
          const response = await storageRef
            .child( `books` )
            .child( `${id}.jpg` )
            .put( file );
          url = await response.ref.getDownloadURL();
          const { ...rest } = data;
          book = rest;
          book.photo = url;
        } else {
          const { photo, ...rest } = data;
          book = rest;
        }

        await db.collection( 'books' ).doc( id ).update( book );
        dispatch({
          type: actionType.SET_UPDATE_BOOK_SUCCESS,
        });
        toast.success( 'Libro editado correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  deleteBook:
    ( id: string ): ThunkAction<
    void, RootState, null, BookActions> => async ( dispatch ) => {
      try {
        await db
          .collection( 'books' )
          .doc( id )
          .update({ deleted: true })
          .then(() => {
            dispatch({
              type: actionType.SET_DELETE_BOOK_SUCCESS,
            });
          });
        toast.success( 'Libro eliminado correctamente.' );
      } catch ( err ) {
        // console.error( err );
      }
    },
  setLoadingBooks:
    ( state: boolean ): ThunkAction<
    void, RootState, null, BookActions> => async ( dispatch ) => {
      dispatch({
        type: actionType.SET_LOADING_BOOK,
        payload: state,
      });
    },
  getBooksByArrayId: ( books: string[]):
  ThunkAction<
  void, RootState, null, AnyAction> => async ( dispatch ) => {
    try {
      if ( books.length > 0 ) {
        let booksList : IBook[] = [];
        await asyncForEach( books, async ( item: string ) => {
          const doc = await db.collection( 'books' )
            .doc( item )
            .get();
          const book = doc.data() as IBook;
          book.id = doc.id;
          booksList.push( book );
        });
        booksList = orderByValue( booksList, 'name' );
        dispatch({
          type: actionType.GET_BOOKS_BY_LIST,
          payload: booksList,
        });
      }
    } catch ( error ) {
      // Error
    }
  },
};
