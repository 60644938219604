import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCV24wUCjMzipFWz6US1jehcX5IV5c0AnQ',
  authDomain: 'my-book-colegio-latino.firebaseapp.com',
  projectId: 'my-book-colegio-latino',
  storageBucket: 'my-book-colegio-latino.appspot.com',
  messagingSenderId: '714039845472',
  appId: '1:714039845472:web:4cdcda9e3c74c4e3c75905',
  measurementId: 'G-X4LLBW33JV',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
